<template>
  <GenericButton
    :class="`bg-[#212B36] border text-white text-sm font-bold transition-all flex items-center gap-2 justify-center rounded-lg px-4 py-2 ${additionalClasses}`"
    @click="onClick"
  >
    <slot>Post</slot> <!-- Default slot content -->
  </GenericButton>
</template>

<script>
import { defineComponent } from 'vue';
import GenericButton from './GenericButton.vue';

export default defineComponent({
  name: 'PostButton',
  components:{
    GenericButton
  },
  props: {
    additionalClasses: {
      type: String,
      default: ''
    }
  },
  emits: ['click'], // Emit click event
  methods: {
    onClick(event) {
      this.$emit('click', event); // Emit an event for parent component
    }
  }
});
</script>

<style scoped>

</style>
